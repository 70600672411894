/* html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1em;
}

.App h1 {
  text-transform: uppercase;
}

.App p {
  text-justify: inter-word;
  text-align: justify;
}

.App {
  display: flex;
  max-width: 1500px;
  min-width: 1300px;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
}

.borders > div:not(:first-child) {
  border-left: solid 3px grey;
}

.borders-horizontal > div:not(:first-child) {
  border-top: solid 3px grey;
}

.map-sidebar {
  max-height: 100%;
  display: flex;
  flex-direction: column;
} */

.map-with-pointers {
  /* width: 100%;
  height: 100%; */
  /* overflow-y: scroll; */
  position: relative;
}

/* .map-img img {
  width: 100%;
  height: 100%;
} */

/* .map-img {
  display: block;
  width: 600px;
} */
/*
.info-view {
  flex-grow: 1;
  flex: 1;
  flex-direction: column;
  display: flex;
  min-width: 200px;
}

.image-view {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
}

.image-view-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 3px grey;
  padding-top: 100px;
}

.image-view-button {
  margin: 100px 0;
}

.image-view img {
  max-width: 100%;
  object-fit: contain;
  padding: 0 50px;
}

.image-view-item:last-child {
  margin-bottom: 100px;
  border-top: solid 3px grey;
}

.image-view p {
  max-width: 500px;
} */

.pointer-img {
  width: 50px;
  position: absolute;
  top: 10%;
}

.pointer-img:not(.active):not(:hover) {
  filter: grayscale(35%);
}
/*
.feed {
  display: flex;
  justify-content: center;
  width: 100%;
} */
